<template>
  <div>
    <iframe
      v-if="item"
      class="demos__iframe w-100"
      :src="demoUrl"
      :style="{ height: height + 'px' }"
      frameborder="0"
      allowfullscreen="allowfullscreen"
      @load="onIframeLoaded"
    ></iframe>
    <slot v-else name="fallback"></slot>
  </div>
</template>

<script>
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useLayoutStore } from "@/stores/layout"

export default {
  props: {
    height: {
      type: Number,
      default: null,
    },
    resource: {
      type: String,
      default: () => "",
    },
    providerParams: {
      type: Object,
      default: () => {},
    },
    demoUrl: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      item: null,
    }
  },
  computed: {
    ...mapStores(useLayoutStore),
  },
  mounted() {
    this.loadData()
  },
  methods: {
    onIframeLoaded() {
      this.layoutStore.isMiniSidebar = true
      this.$nextTick(() =>
        document.documentElement.classList.add("overflow-y-hidden")
      )
    },
    async loadData() {
      let params = this.providerParams
      params.resource = this.resource
      const response = await AdminApi.search.search({
        params,
      })
      const [item] = response?.items || []
      this.$emit("load-item", item)
      this.item = item
    },
  },
}
</script>

<style lang="scss" scoped>
.demos {
  &__iframe {
    width: 100%;
    box-shadow: none;
  }
}
</style>
