<template>
  <div>
    <DemoIframe
      :key="$route.path"
      v-bind="$attrs"
      resource="cameras"
      :providerParams="{ term: $route.params.cameraId }"
      :demoUrl="demoUrl"
      @load-item="loadCamera"
    />
  </div>
</template>

<script>
import DemoIframe from "@/components/DemoIframe"
import { AdminApi } from "@evercam/shared/api/adminApi"
export default {
  components: {
    DemoIframe,
  },
  data() {
    return {
      demoUrl: "",
    }
  },
  methods: {
    async loadCamera(camera) {
      const { token } = await AdminApi.users.impersonateUser(camera?.email)
      this.demoUrl = `${this.$config.public.dashURL}/projects/${
        camera.projectId
      }/${camera.exid}${
        this.$route.query?.page ? `${this.$route.query?.page}` : ""
      }${
        this.$route.query?.page?.includes("?") ? "&" : "?"
      }impersonation_token=${token}&demo=true`
    },
  },
}
</script>
